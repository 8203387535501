import "./App.css";

function App() {
  const isMobileDevice = () => {
    return window.innerWidth <= 768; // Adjust the threshold as needed
  };

  const openTelegram = () => {
    window.open("https://telegram.me/+7MYrh7U0a1RkMzk1", "_blank");
    // window.open("https://t.me/your-telegram-channel", "_blank");
  };

  return (
    <div className="App" onClick={isMobileDevice() ? openTelegram : null}>
      <div className="box">
        <h3
          style={{
            color: "white",
            fontSize: "2.2rem",
            padding: 0,
            margin: 0,
            marginBottom: "0.6rem",
          }}
        >
          NITIN MENON
        </h3>
        <img
          src="nitin.jpg"
          style={{
            height: "10rem",
            width: "10rem",
            borderRadius: "50%",
            marginBottom: "1.7rem",
            border: "2px solid white",
          }}
          alt="Logo"
        />
        <button
          class="button-85"
          onClick={isMobileDevice() ? openTelegram : null}
        >
          <div
            className="button"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <img
                src="logo.webp"
                style={{
                  height: "1.8rem",
                  width: "1.8rem",
                  display: "inline",
                }}
                alt="Logo"
              />
            </div>
            <div style={{ fontWeight: "bolder", fontSize: "1.5rem" }}>
              &nbsp;&nbsp;JOIN TELEGRAM
            </div>
          </div>
        </button>
        <div className='divPara'>
          <p>𝗠𝗢𝗦𝗧 𝗣𝗢𝗣𝗨𝗟𝗔𝗥 𝗨𝗠𝗣𝗜𝗥𝗘 ⛳</p>
          <p>𝗕𝗘𝗦𝗧 𝗖𝗥𝗜𝗖𝗞𝗘𝗧 𝗔𝗡𝗔𝗟𝗬𝗦𝗧⛳ </p>
          <p>𝗔𝗟𝗟 𝗙𝗜𝗫𝗘𝗗 𝗥𝗘𝗣𝗢𝗥𝗧𝗦 𝗢𝗡𝗟𝗬⛳</p>
          <p>𝗔𝗟𝗟 𝗖𝗥𝗜𝗖𝗞𝗘𝗧 𝗣𝗟𝗔𝗬𝗘𝗥𝗦 𝗔𝗥𝗘 𝗙𝗥𝗜𝗘𝗡𝗗𝗦 𝗢𝗙 𝗠𝗜𝗡𝗘 ⛳</p>
        </div>
        <p style={{ marginBottom:'0.7rem', fontSize:'1.2rem' }}>👇 𝟭𝟬𝟬% 𝗦𝗨𝗥𝗘 𝗜𝗣𝗟 𝗥𝗘𝗣𝗢𝗥𝗧𝗦 👇</p>
        <p
          style={{
            padding: "1.2rem",
            borderRadius: "60px",
            fontFamily: "Arial Narrow Bold, sans-serif",
            fontWeight: "bolder",
            fontSize: "1.3rem",
            marginTop: "1.2rem",
            background: "#002C3E",
            border: '2px solid white',
            color: "#F7F8F3",
          }}
        >
          𝗪𝗢𝗥𝗟𝗗'𝗦 𝗕𝗘𝗦𝗧 𝗧𝗜𝗣𝗣𝗘𝗥
        </p>
      </div>
    </div>
  );
}

export default App;
